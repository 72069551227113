@import "../node_modules/breakpoint-sass/stylesheets/breakpoint";

// Pulse Grid
$susy: (
  flow: ltr,
  output: float,
  math: fluid,
  container: 100%,
  gutters: 0,
  columns: 4,
);

// Grow
.grow {
  animation: grow 1s;

}

@-webkit-keyframes grow {
 0% {
   transform: scale(0);
 }

 100% {
   transform: scale(1);
  }
}

//Primary Colors
$icon-red: rgb(215, 27, 54);
$icon-red-alpha: rgba(215, 27, 54, 0.9);
$icon-red-dark: rgb(91, 11, 23);
$icon-red-alpha-light: rgba(215, 27, 54, 0.1);

$icon-lightgray: rgb(163, 167, 169);
$icon-gray: rgb(121, 125, 130);
$icon-darkgray: rgb(72, 76, 85);

// Accent Colors
$icon-red-error: rgb(255, 0, 0);
$icon-red-error-dark: rgb(136, 17, 35);

$icon-orange: rgb(255, 78, 0);
$icon-orange-dark: rgb(153, 46, 0);

$icon-blue: rgb(51, 100, 127);
$icon-blue-dark: rgb(29, 58, 73);
$icon-blue-alpha: rgba(51, 100, 127, 0.2);

$icon-slate: rgb(65, 84, 100);
$icon-slate-alpha: rgba(65, 84, 100, 0.95);
$icon-stone: rgb(118, 134, 147);

$icon-black: rgba(25, 25, 25, 0.95);

// Breakpoints
$desktop: min-width 960px;


body {
  font-family: 'Open Sans', sans-serif;
  color: #000;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  &::selection {
    background: $icon-red;
  }
}

/* Colors */
.red {
  background: $icon-red;
}

.lightgray {
  background: $icon-lightgray;
}

.gray {
  background: $icon-gray;
}

.darkgray {
  background: $icon-darkgray;
}

.black {
  background: #000;
}

.errorred {
  background: $icon-red-error;
}

.orange {
  background: $icon-orange;
}

.blue {
  background: $icon-blue;
}

.slate {
  background: $icon-slate;
}

.slate-alpha {
  background: $icon-slate-alpha;
}

.stone {
  background: $icon-stone;
}

/* Alerts */

.alert-info {
  background: $icon-blue;
  border-color: $icon-blue-dark;
  color: #fff;
}

.alert-warning {
  background: $icon-orange;
  border-color: $icon-orange-dark;
  color: #fff;
}

.alert-danger {
  background: $icon-red-error;
  border-color: $icon-red-error-dark;
  color: #fff;
}

/* Headings */

h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: #d71b36;
}

/* Type */

a {
  color: $icon-red;
  text-decoration: underline;
  &:hover {
    color: #000;
  }
  
  &:focus {
    color: #000;
  }
}

p {
  font-size: 1.1em;
  line-height: 1.5;
}

/* Tables */

.table-default {
  thead {
    th {
      border-color: $icon-darkgray;
    }
  }

  td,
  th {
    border-color: $icon-darkgray;
  }
}

.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &.table-active {
        &:nth-of-type(odd) {
          background-color: $icon-blue-alpha;
        }
      }
    }
  }
}

.table-hover {
  tbody {
    tr {
      &:nth-of-type(odd) {
        &:hover {
          background-color: $icon-blue-alpha;
        }
      }

      &:hover {
        background-color: $icon-blue-alpha;
      }
    }
  }

  .table-active {
    &:hover {
      > td,
      > th {
        background-color: $icon-blue-alpha;
      }
    }
  }
}

.table-active {
  &,
  > th,
  > td {
    background-color: $icon-blue-alpha;

    &:hover {
      background-color: $icon-blue-alpha;
    }
  }
}

.thead-inverse {
  th {
    font-weight: normal;
  }
}

td {
  &.details-control {
    position: relative;
    &::after {
      background-color: #fff;
      border: 2px solid $icon-blue;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      content: '+';
      color: $icon-blue;
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      height: 20px;
      line-height: 20px;
      position: absolute;
      text-align: center;
      top: 10px;
      left: 5px;
      width: 20px;
    }
  }
}

tr {
  &.shown {
    td {
      &.details-control {
        &::after {
          border-color: $icon-red;
          color: $icon-red;
          content: '-';
          line-height: 18px;
        }
      }
    }
  }
}

.child-row-table {
  margin-bottom: 0 !important;
  padding-left: 50px;

  tr {
    background-color: transparent !important;

    &:first-of-type {
      td {
        border-top: none;
      }
    }
  }
}

.dataTables_length {
  select {
    background: url(../images/icon-arrow-down.svg) right 10px center no-repeat #fff !important;
    width: 70px !important;
  }
}

table.table-filterable {
  th {
    position: relative;

    &:focus {
      outline: none;
    }

    i.fa.fa-filter {
      color: #b3b3b3;
      cursor: pointer;
      float: left;
      left: 0;
      line-height: 38px;
      padding: 0 5px 0 0;
      position: absolute;
      z-index: 3;

      &.selected {
        color: #000;
      }

      &.applied {
        color: $icon-red;
      }
    }

    span {
      line-height: 38px;
      margin-left: 6px;
    }

    .form-control {
      &.filter-search {
        background: #fff url(http://i.stack.imgur.com/mJotv.gif) no-repeat right -10px center;
        display: none;
        float: left;
        font-weight: normal;
        margin-left: 6px;
        padding-right: 17px;
        -webkit-transition: background 0.4s;
        transition: background 0.4s;

        &.x {
          background-position: right 5px center;
        }

        &.onX {
          cursor: pointer;
        }

        &::-ms-clear {
          display: none;
          height: 0;
          width: 0;
        }
      }
    }
  }
}

/* Tabs */

.nav-tabs .nav-item a.nav-link {
  background-color: #fff;
  border-color: #ccc #ccc #ddd;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    background-color: #eee;
    border-color: #ccc #ccc #ddd;
  }

  &:focus,
  &.active {
    background-color: #eee;
    border-color: #ccc #ccc transparent;
    color: #000;

    &:hover {
      color: #000;
    }
  }

  &.disabled {
    background-color: #fff;
    border-color: #ccc #ccc #ddd;
    color: $icon-gray;
    font-weight: normal;

    &:hover {
      border-color: #ccc #ccc #ddd;
    }
  }
}

.tab-content {
  background-color: #eee;
  border: 1px solid;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-color: transparent #ccc #ccc;
  padding: 2rem 1rem;
}

.tabs-alternate {
  .nav-tabs .nav-item a.nav-link {
    background-color: #fff;
  }
  .tab-content {
    background-color: #fff;
  }
}

/* Buttons */

.btn {
  display: inline-block;
  text-transform: uppercase;
  border-radius: 20px;
  padding: 10px 30px;
  font-size: 16px;
  transition: .5s;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &.mobile {
    padding: 11px 30px;
  }
}

.btn-default {
  background-color: $icon-red;
  border: 1px solid $icon-red;
  color: #fff;
  
  &:focus {
    background-color: #AD0025;
    color: #fff;
  }
  
  &:hover {
    background-color: $icon-red-dark;
    border: 1px solid $icon-red-dark;
    color: #fff;
  }
}

.btn-default-inv {
  background-color: $icon-red;
  border: 1px solid $icon-red;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: $icon-red;
  }
}

.btn-primary {
  background-color: $icon-blue;
  border-color: $icon-blue;
  
  &:hover {
    background-color: $icon-blue-dark;
    border-color: $icon-blue-dark;
    color: #fff;
  }
}

.btn-primary-inv {
  background-color: $icon-blue;
  border-color: $icon-blue;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: $icon-blue;
  }
}

.btn-warning {
  background-color: $icon-orange;
  border-color: $icon-orange;

  &:hover {
    background-color: $icon-orange-dark;
    border-color: $icon-orange-dark;
  }
}

.btn-warning-inv {
  background-color: $icon-orange;
  border-color: $icon-orange;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: $icon-orange;
  }
}

.btn-inverse {
  border: 1px solid $icon-red;
  background-color: #fff;
  color: $icon-red;
  
  &:hover {
    border: 1px solid $icon-red;
    background-color: $icon-red;
    color: #fff;
  }
  
}

.btn-achromatic {
  background-color: #000;
  color: #fff;

  &:hover {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
  }
}

.btn-disabled {
  background-color: $icon-lightgray;
  border: 1px solid $icon-lightgray;
  color: $icon-gray;

  &:focus,
  &:hover {
    background-color: $icon-lightgray;
    border: 1px solid $icon-lightgray;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $icon-gray;
    cursor: not-allowed;
  }
}

.btn-link {
  color: $icon-red;

  &:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #000;
  }
}

.btn-link-visited {
  color: $icon-blue;
  text-decoration: underline;
}

.btn-link-disabled {
  color: $icon-lightgray;
  cursor: not-allowed;

  &:hover {
    color: $icon-lightgray;
    text-decoration: none;
  }
}

/* Form Elements */
.form-control {
  border-color: silver;
  color: #000;

  &:focus {
    border-color: #000;
    color: #000;
  }

  &.mobile {
    min-height: 44px;

    option {
      padding: 11px 0;
    }
  }
}

fieldset { padding: 5px;}

fieldset:focus {
  outline: none;
  background: #efefef;
}

select.form-control:not([size]):not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../images/icon-arrow-down.svg") right 17px center no-repeat #fff;
  border: 1px solid silver;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #000;
  height: 40px;
  width: 287px;
  padding: 0 17px;
  
  &:focus {
    border-color: #000;
    color: #000;
  }

  &.mobile {
    height: 44px;
  }
}

input[type="file"] {
  padding: 10px;
  background: #efefef;
}

.text-muted {
  color: #484c55 !important;
}

/* Carousel */
.carousel-indicators {
  li {
    background: $icon-red;

    &.active {
      background: black;
    }
  }
}

// Radios and Checkboxes

.form-check-label {
  padding-left: 0;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 18px;
  
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  
  .form-indicator {
    display: block;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 2px;
    left: 0;
    background: #e6e6e6;
    border: 1px solid #ccc;
    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }
  
  // Checked
  input:checked ~ .form-indicator {
    background: $icon-red;
    &:after {
      display: block;
    }
  }
   
  // On Focus and Hover 
  input:not([disabled]):focus ~ .form-indicator,
  input:not([disabled]):hover ~ .form-indicator {
    background: #ccc;
  }
  
  // Radio button inner circle
  &.form-radio .form-indicator {
    border-radius: 50%;
    &:after {
      left: 5px;
      top: 5px;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: #fff;
    }
  }

}

.form-check .form-indicator {
  &:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.form-check-label:hover input:not([disabled]):checked ~ .form-indicator, 
.form-check-label input:checked:focus ~ .form-indicator {
  background: #AD0025;
}

.form-mobile {
  padding-top: 6px;
  padding-bottom: 10px;
  padding-left: 40px;
}

.form-mobile {
  .form-indicator {
    width: 30px;
    height: 30px;
  }
}

.form-radio.form-mobile {
  .form-indicator {
    &:after {
      width: 13px;
      height: 13px;
      top: 7px;
      left: 7px;
      border-radius: -1px;
    }
  }
}

.form-checkbox.form-mobile {
  .form-indicator {
    &:after {
      width: 8px;
      height: 15px;
      top: 5px;
      left: 10px;
    }
  }
}

.slick-next,
.slick-prev {
  height: 40px;
  width: 40px;
  z-index: 1;

  &::before {
    font-family: 'FontAwesome';
    font-size: 40px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  }
}

.slick-next {
  right: 10px;

  &::before {
    content: '\f054';
  }
}

.slick-prev {
  left: 10px;

  &::before {
    content: '\f053';
  }
}

.slick-slide {
  position: relative;
}

.slick-dots {
  bottom: initial;

  li {
    height: 40px;
    margin: 0;
    width: 40px;

    button {
      height: 40px;
      width: 40px;

      &::before {
        color: $icon-red;
        font-size: 24px;
        height: 40px;
        line-height: 40px;
        opacity: 1;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
        width: 40px;
      }
    }
    
    &.slick-active {
      button {
        &::before {
          color: #000;
          font-size: 30px;
          opacity: 1;
        }
      }
    }
  }
}

/* Accordion */
.card {
  .card-header {
    position: relative;
    background: none;

    a { text-decoration: none; display: block;}

    a:after {
      content: '+';
      font-family: OpenSans-Light;
      font-size: 48px;
      color: #484C55;
      line-height: 48px;
      position: absolute;
      top: 0;
      right: 5px;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
      transform: rotate(45deg);

    }

    a.collapsed:after {
      transform: rotate(0deg);
    }

  }
}

.minimalist-accordion {
  .card {
    border: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    margin-bottom: 0;

    &.active {
      background-color: rgba(163,167,169,0.1);

      .card-header {
        border: none;
      }
    }
  }

  .card-header {
    border-bottom: 2px solid rgba(163, 167, 169, 0.3);
    margin: 0 auto;
    width: 75%;

    a {
      color: $icon-darkgray;
      line-height: 30px;
    }
  }

  .card-block {
    color: $icon-darkgray;
    margin: 0 auto;
    width: 75%;
  }
}

/* Progress */
.progress {
  height: 1.5rem;
}

/* Drag & Drop Control */
.dd-container {
  border: 2px solid $icon-lightgray;
  border-radius: 5px;
  height: 150px;
  list-style-type: none;
  padding: 20px;
  width: 100%;

  li {
    float: left;
    height: 100px;
    text-align: center;
    width: 20%;

    &.placeHolder {

      div {
        background-color: #fff;
        border: dashed 2px $icon-blue;
      }
    }

    div {
      background-color: #E0E0E0;
      border: solid 2px $icon-blue;
      border-radius: 5px;
      display: inline-block;
      height: 110px;
      line-height: 110px;
      padding: 10px;
      text-align: center;
      width: 110px;

      span {
        color: $icon-slate;
        display: inline-block;
        line-height: normal;
      }
    }
  }
}

/* Dropdown Button */
.btn-with-dropdown {
  display: inline-block;
  position: relative;

  & > a {
    margin-left: 0;
    position: relative;
    z-index: 5;

    &::after {
      content: '\f078';
      font-family: FontAwesome;
      padding-left: 5px;
    }
  }

  .btn-dropdown-container {
    background-color: #fff;
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 55px;
    width: 150%;
    z-index: 4;

    .btn-dropdown-option {
      border-bottom: 1px solid #efefef;
      padding: 10px;

      &:last-of-type {
        border-bottom: none;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &:hover {
    a {
      background-color: #fff;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: $icon-red;
    }

    .btn-dropdown-container {
      background: #fff;
      border: 1px solid $icon-red;
      border-radius: 5px;
      border-top-left-radius: 0;
      display: block;
      font-size: 12px;
    }
  }
}

/* Dropzone */
.dropzone {
  border-radius: 5px;
  border-color: #ccc;
  background: transparent url(../images/icon-fileupload-fade.png) no-repeat center center;
  background-size: contain;
  padding: 40px;
  color: gray;
  text-shadow: 1px 1px 1px #fff;
}

/* Number Slider Control */
.slider {

  .tooltip-inner {
    padding: 6px;
  }

  .slider-selection {
    background: $icon-lightgray;
  }

  .slider-handle {
    background-color: $icon-blue;
    background-image: none;
  }
}

/* Pagination */
.pagination {
  .page-link {
    color: $icon-red;
    border-color: $icon-red;
    padding: 4px 20px;
    border-radius: 15px;
    margin-right: 5px;
    font-size: 14px;
    line-height: 1;
    box-sizing: content-box;

    &:last-child {
      border-radius: 15px;
    }

    &:hover {
      background: $icon-red;
      color: #fff;
      text-decoration: none;
    }
  }

  .page-item {
    .page-link {
      &,
      &:focus {
        outline: none;
      }
    }

    &.active {
      .page-link {
        background: $icon-red;
        color: #fff;
        border-color: $icon-red;
        outline: none;

        &,
        &:focus {
          outline: none;
        }
      }

      &:hover {
        background: $icon-red-dark;
      }
    }
  }

  &.mobile-pagination {
    .page-info {
      display: block;
      float: left;
      line-height: 46px;
      margin-right: 15px;
      position: relative;
    }
    .page-link {
      border-width: 2px;
      font-weight: bold;
      margin-right: 15px;
      padding: 14px 20px;
      text-transform: uppercase;

      &:last-child {
        border-radius: 5px;
      }

      &.prev {
        margin-left: 20px;
        padding-left: 21px;
        padding-right: 25px;
        position: relative;

        &::before {
          background: inherit;
          border: inherit;
          border-bottom-color: transparent;
          border-left-color: transparent;
          border-radius: 0 4px 0 0;
          -webkit-border-radius: 0 4px 0 0;
          -moz-border-radius: 0 4px 0 0;
          content: "";
          height: 27px;
          left: -16px;
          position: absolute;
          top: 5px;
          transform: rotate(225deg);
          -webkit-transform: rotate(225deg);
          -moz-transform: rotate(225deg);
          -o-transform: rotate(225deg);
          -ms-transform: rotate(225deg);
          width: 27px;
          z-index: 1;
        }
      }

      &.next {
        padding-left: 25px;
        padding-right: 20px;
        position: relative;

        &::after {
          background: inherit;
          border: inherit;
          border-bottom-color: transparent;
          border-left-color: transparent;
          border-radius: 0 4px 0 0;
          -webkit-border-radius: 0 4px 0 0;
          -moz-border-radius: 0 4px 0 0;
          content: "";
          height: 27px;
          right: -16px;
          position: absolute;
          top: 5px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          width: 27px;
          z-index: 1;
        }
      }
    }
  }
}

/* Circle */
.circle {
  display: inline-block;
  background-color: $icon-red;
  border: 5px solid #FFF;
  border-radius: 65px;
  box-shadow: 0 0 1px #000;
  height: 130px;
  width: 130px;
  margin: 0 0 40px 0;
  color: #fff;
  @extend .grow;

  .text {
    text-align: center;
    font-weight: bold;
    font-size: 0.8em;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    padding-top: 27%;
  }

  strong {
    font-size: 2em;
    display: block;
  }
}

/* Tree Style Nav */

ul {
  &.nav {
    &.nav-list {
      li {
        border-bottom: 1px solid #efefef;
        color: #000;
        display: block;
        font-size: 14px;
        font-weight: normal;
        text-decoration: none;

        a {
          color: $icon-darkgray;
          text-decoration: none;

          &:hover {
            color: $icon-red;
          }
        }

        & > a {
          color: #000;
          font-weight: 700;
          line-height: 30px;
        }

        label {
          cursor: pointer;
          font-weight: 700;
          line-height: 30px;
          margin-bottom: 0;

          &::after {
            color: #000;
            content: '+';
            cursor: pointer;
            font-family: OpenSans-Light, serif;
            font-size: 24px;
            line-height: 28px;
            margin-left: 8px;
            position: absolute;
            -webkit-transition: 0.2s ease-in-out;
            transition: 0.2s ease-in-out;
          }

          &.collapsed {
            &::after {
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

          &:hover {
            color: $icon-red;

            &::after {
              color: $icon-red;
            }
          }
        }
      }

      &.tree {
        display: none;

        li {
          border-bottom: none;
          margin-left: 20px;

          & > a {
            font-weight: normal;
          }
        }
      }
    }
  }
}

/* Main Navigation Bar */
#mainnav {
  /* to fix style guide */
  height: 426px;

  @include breakpoint($desktop) {
    height: auto;
  }
}

.content-header {
  background-color: $icon-black;
  height: 50px;
  left: 0;
  padding-left: 200px;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  z-index: 52;
  /* to fix style guide */
  position: relative;

  .logo {
    left: 15px;
    position: absolute;
    top: 15px;
  }

  #block-icon-main-menu {
    display: inline-block;

    ul {
      &.menu {
        display: none;

        @include breakpoint($desktop) {
          display: block;
          left: 0;
          list-style: none;
          margin: 0;
          padding: 0;
          right: 0;
        }

        li {
          display: inline-block;
          padding-right: 20px;
          position: relative;

          &:hover {
            & > ul {
              display: block;
            }
          }

          &.has-submenu {

            & > a:after {
              content: '\f107';
              font-family: 'FontAwesome';
              margin-left: 5px;
            }
          }

          a {
            color: #fff;
            font-size: 0.9em;
            line-height: 50px;
            text-decoration: none;
            text-transform: uppercase;

            &:hover,
            &.is-active {
              border-bottom: 2px solid $icon-red;
              color: #fff;
              padding-bottom: 2px;
            }
          }
        }
      }

      &.submenu {
        background-color: #000;
        border-top: 1px solid $icon-lightgray;
        display: none;
        left: 0;
        padding: 0;
        position: absolute;
        top: 100%;

        li {
          border-bottom: 1px solid $icon-lightgray;
          float: none;
          padding-left: 15px;
          padding-right: 15px;
          width: 200px;

          &:hover {
            background-color: $icon-darkgray;
          }

          a {
            line-height: 35px;
            padding: 10px 0;

            &:hover {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .burger {
    background: transparent url(/images/icon-burger.png) no-repeat;
    background-size: 100%;
    color: #fff;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 35px;

    &.active {
      background: transparent url(/images/icon-burger-active.png) no-repeat;
      background-size: 100%;
    }
  }

  .mobile-menu {
    background: $icon-red-alpha;
    display: none;
    height: 100%;
    position: fixed;
    right: 0;
    text-align: center;
    top: 50px;
    width: 100%;
    z-index: 200;
    /* to fix style guide */
    min-height: 200px;
    position: absolute;

    ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;

      li {
        a {
          border-bottom: 2px solid #fff;
          color: #fff;
          display: block;
          font-size: 0.9em;
          margin: 0 20px;
          padding: 10px;
          text-decoration: none;
        }
      }
    }

    &.menu {
      & > li {

        &:last-of-type {
          margin-bottom: 20px;
        }

        &.has-submenu {

          & > a:after {
            content: '\f107';
            font-family: 'FontAwesome';
            margin-left: 5px;
          }
        }
      }
    }

    .submenu {
      display: none;

      &.visible {
        display: block;
      }
    }
  }

  .visually-hidden {
    clip: rect(1px,1px,1px,1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal;
  }

  .clearfix {
    &::after {
      clear: both;
      content: "";
      display: table;
    }
  }

  .hidden-mobile {
    display: block;

    @include breakpoint($desktop) {
      display: none !important;
    }
  }

  .noscroll {
    overflow: hidden;
  }
}

/* Main nav with Login */
.login {
  float: right;
  display: block;
  background: $icon-red;
  color: #fff;
  text-decoration: none;
  margin-top: 14px;
  padding: 4px 15px;
  border-radius: 15px;
  margin-right: 5px;
  font-size: 0.8em;
  border: 1px solid $icon-gray;
  
  &:hover {
    text-decoration: none;
    color: #fff;
    background: $icon-darkgray;
  }
  
  @include breakpoint(max-width 959px) {
    margin-right: 60px;
  }
}

.mobile-container .login {
  margin-right: 60px;
}

/* Add and Remove Circles */
.btn-circle {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  height: 44px;
  width: 44px;

  &::after {
    background-color: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 34px;
    font-weight: bold;
    height: 34px;
    line-height: 34px;
    margin-left: 4px;
    margin-top: 4px;
    position: absolute;
    text-align: center;
    width: 34px;
  }

  &.add {
    background: $icon-blue;
    border: 1px solid $icon-blue;

    &::after {
      color: $icon-blue;
      content: '+';
    }

    &:hover {
      background: $icon-blue-dark;
      border: 1px solid $icon-blue-dark;

      &::after {
        color: $icon-blue-dark;
      }
    }
  }

  &.remove {
    background: $icon-red;
    border: 1px solid $icon-red;

    &::after {
      color: $icon-red;
      content: '-';
      line-height: 29px;
    }

    &:hover {
      background: $icon-red-dark;
      border: 1px solid $icon-red-dark;

      &::after {
        color: $icon-red-dark;
      }
    }
  }
}

/* Tooltips */
.tooltip-inner {
  background-color: $icon-blue;
  padding: 10px;
}

.tooltip {
  &.bs-tether-element-attached-bottom,
  &.tooltip-top {
    .tooltip-inner::before {
      border-top-color: $icon-blue;
    }
  }

  &.bs-tether-element-attached-left,
  &.tooltip-right {
    .tooltip-inner::before {
      border-right-color: $icon-blue;
    }
  }

  &.bs-tether-element-attached-top,
  &.tooltip-bottom {
    .tooltip-inner::before {
      border-bottom-color: $icon-blue;
    }
  }

  &.bs-tether-element-attached-right,
  &.tooltip-left {
    .tooltip-inner::before {
      border-left-color: $icon-blue;
    }
  }
}

/* Error Indicator */
.has-danger {
  .form-control-feedback,
  .form-control-label,
  .form-check-label,
  .form-check-inline,
  .custom-control {
    color: $icon-red-error;
  }

  .form-control {
    border-color: $icon-red-error;
  }
}

/* Loading Indicator */
.loader {
  -webkit-animation: load 1.1s infinite ease;
  animation: load 1.1s infinite ease;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  height: 1em;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  width: 1em;
}

@-webkit-keyframes load {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $icon-red, 1.8em -1.8em 0 0em rgba(215,27,54, 0.2), 2.5em 0em 0 0em rgba(215,27,54, 0.2), 1.75em 1.75em 0 0em rgba(215,27,54, 0.2), 0em 2.5em 0 0em rgba(215,27,54, 0.2), -1.8em 1.8em 0 0em rgba(215,27,54, 0.2), -2.6em 0em 0 0em rgba(215,27,54, 0.5), -1.8em -1.8em 0 0em rgba(215,27,54, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.7), 1.8em -1.8em 0 0em $icon-red, 2.5em 0em 0 0em rgba(215,27,54, 0.2), 1.75em 1.75em 0 0em rgba(215,27,54, 0.2), 0em 2.5em 0 0em rgba(215,27,54, 0.2), -1.8em 1.8em 0 0em rgba(215,27,54, 0.2), -2.6em 0em 0 0em rgba(215,27,54, 0.2), -1.8em -1.8em 0 0em rgba(215,27,54, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.5), 1.8em -1.8em 0 0em rgba(215,27,54, 0.7), 2.5em 0em 0 0em $icon-red, 1.75em 1.75em 0 0em rgba(215,27,54, 0.2), 0em 2.5em 0 0em rgba(215,27,54, 0.2), -1.8em 1.8em 0 0em rgba(215,27,54, 0.2), -2.6em 0em 0 0em rgba(215,27,54, 0.2), -1.8em -1.8em 0 0em rgba(215,27,54, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.2), 1.8em -1.8em 0 0em rgba(215,27,54, 0.5), 2.5em 0em 0 0em rgba(215,27,54, 0.7), 1.75em 1.75em 0 0em $icon-red, 0em 2.5em 0 0em rgba(215,27,54, 0.2), -1.8em 1.8em 0 0em rgba(215,27,54, 0.2), -2.6em 0em 0 0em rgba(215,27,54, 0.2), -1.8em -1.8em 0 0em rgba(215,27,54, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.2), 1.8em -1.8em 0 0em rgba(215,27,54, 0.2), 2.5em 0em 0 0em rgba(215,27,54, 0.5), 1.75em 1.75em 0 0em rgba(215,27,54, 0.7), 0em 2.5em 0 0em $icon-red, -1.8em 1.8em 0 0em rgba(215,27,54, 0.2), -2.6em 0em 0 0em rgba(215,27,54, 0.2), -1.8em -1.8em 0 0em rgba(215,27,54, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.2), 1.8em -1.8em 0 0em rgba(215,27,54, 0.2), 2.5em 0em 0 0em rgba(215,27,54, 0.2), 1.75em 1.75em 0 0em rgba(215,27,54, 0.5), 0em 2.5em 0 0em rgba(215,27,54, 0.7), -1.8em 1.8em 0 0em $icon-red, -2.6em 0em 0 0em rgba(215,27,54, 0.2), -1.8em -1.8em 0 0em rgba(215,27,54, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.2), 1.8em -1.8em 0 0em rgba(215,27,54, 0.2), 2.5em 0em 0 0em rgba(215,27,54, 0.2), 1.75em 1.75em 0 0em rgba(215,27,54, 0.2), 0em 2.5em 0 0em rgba(215,27,54, 0.5), -1.8em 1.8em 0 0em rgba(215,27,54, 0.7), -2.6em 0em 0 0em $icon-red, -1.8em -1.8em 0 0em rgba(215,27,54, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.2), 1.8em -1.8em 0 0em rgba(215,27,54, 0.2), 2.5em 0em 0 0em rgba(215,27,54, 0.2), 1.75em 1.75em 0 0em rgba(215,27,54, 0.2), 0em 2.5em 0 0em rgba(215,27,54, 0.2), -1.8em 1.8em 0 0em rgba(215,27,54, 0.5), -2.6em 0em 0 0em rgba(215,27,54, 0.7), -1.8em -1.8em 0 0em $icon-red;
  }
}
@keyframes load {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $icon-red, 1.8em -1.8em 0 0em rgba(215,27,54, 0.2), 2.5em 0em 0 0em rgba(215,27,54, 0.2), 1.75em 1.75em 0 0em rgba(215,27,54, 0.2), 0em 2.5em 0 0em rgba(215,27,54, 0.2), -1.8em 1.8em 0 0em rgba(215,27,54, 0.2), -2.6em 0em 0 0em rgba(215,27,54, 0.5), -1.8em -1.8em 0 0em rgba(215,27,54, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.7), 1.8em -1.8em 0 0em $icon-red, 2.5em 0em 0 0em rgba(215,27,54, 0.2), 1.75em 1.75em 0 0em rgba(215,27,54, 0.2), 0em 2.5em 0 0em rgba(215,27,54, 0.2), -1.8em 1.8em 0 0em rgba(215,27,54, 0.2), -2.6em 0em 0 0em rgba(215,27,54, 0.2), -1.8em -1.8em 0 0em rgba(215,27,54, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.5), 1.8em -1.8em 0 0em rgba(215,27,54, 0.7), 2.5em 0em 0 0em $icon-red, 1.75em 1.75em 0 0em rgba(215,27,54, 0.2), 0em 2.5em 0 0em rgba(215,27,54, 0.2), -1.8em 1.8em 0 0em rgba(215,27,54, 0.2), -2.6em 0em 0 0em rgba(215,27,54, 0.2), -1.8em -1.8em 0 0em rgba(215,27,54, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.2), 1.8em -1.8em 0 0em rgba(215,27,54, 0.5), 2.5em 0em 0 0em rgba(215,27,54, 0.7), 1.75em 1.75em 0 0em $icon-red, 0em 2.5em 0 0em rgba(215,27,54, 0.2), -1.8em 1.8em 0 0em rgba(215,27,54, 0.2), -2.6em 0em 0 0em rgba(215,27,54, 0.2), -1.8em -1.8em 0 0em rgba(215,27,54, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.2), 1.8em -1.8em 0 0em rgba(215,27,54, 0.2), 2.5em 0em 0 0em rgba(215,27,54, 0.5), 1.75em 1.75em 0 0em rgba(215,27,54, 0.7), 0em 2.5em 0 0em $icon-red, -1.8em 1.8em 0 0em rgba(215,27,54, 0.2), -2.6em 0em 0 0em rgba(215,27,54, 0.2), -1.8em -1.8em 0 0em rgba(215,27,54, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.2), 1.8em -1.8em 0 0em rgba(215,27,54, 0.2), 2.5em 0em 0 0em rgba(215,27,54, 0.2), 1.75em 1.75em 0 0em rgba(215,27,54, 0.5), 0em 2.5em 0 0em rgba(215,27,54, 0.7), -1.8em 1.8em 0 0em $icon-red, -2.6em 0em 0 0em rgba(215,27,54, 0.2), -1.8em -1.8em 0 0em rgba(215,27,54, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.2), 1.8em -1.8em 0 0em rgba(215,27,54, 0.2), 2.5em 0em 0 0em rgba(215,27,54, 0.2), 1.75em 1.75em 0 0em rgba(215,27,54, 0.2), 0em 2.5em 0 0em rgba(215,27,54, 0.5), -1.8em 1.8em 0 0em rgba(215,27,54, 0.7), -2.6em 0em 0 0em $icon-red, -1.8em -1.8em 0 0em rgba(215,27,54, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215,27,54, 0.2), 1.8em -1.8em 0 0em rgba(215,27,54, 0.2), 2.5em 0em 0 0em rgba(215,27,54, 0.2), 1.75em 1.75em 0 0em rgba(215,27,54, 0.2), 0em 2.5em 0 0em rgba(215,27,54, 0.2), -1.8em 1.8em 0 0em rgba(215,27,54, 0.5), -2.6em 0em 0 0em rgba(215,27,54, 0.7), -1.8em -1.8em 0 0em $icon-red;
  }
}

/* Combination Dropdown */

.selectize-control {
  &.single {
    .selectize-input {
      background: #fff;
      border: 1px solid #000;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      color: #000;
      font-size: 14px;
      padding: 10px 17px;
      width: 287px;

      &::after {
        background: url(../images/icon-arrow-down.svg) right 17px center no-repeat #fff;
        border: none;
        height: 30px;
        right: 0;
        top: 7px;
        width: 30px;
      }

      &.input-active {
        &::after {
          background: url(../images/icon-arrow-down.svg) right 17px center no-repeat #fff;
          border: none;
          height: 30px;
          right: 0;
          top: 7px;
          width: 30px;
        }

        &.dropdown-active {
          &::after {
            background: url(../images/icon-arrow-down.svg) right 17px center no-repeat #fff;
            border: none;
            margin-top: 0;
            right: 17px;
            top: 4px;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.selectize-dropdown {
  color: #000;
  font-size: 14px;

  &.single {
    border: 1px solid #000;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }

  [data-selectable],
  .optgroup-header {
    padding: 5px 8px  5px 17px;
  }

  .active {
    background-color: #d4d4d4;
    color: #000;
  }
}

.selectize-input {
  font-size: 14px;

  .dropdown-active {
    &::before {
      background: #000;
    }
  }

  input {
    font-size: 14px;
    width: 235px;
  }
}

.bootstrap-select {
  &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 287px;
  }

  &.btn-group {
    .btn.dropdown-toggle {
      background-color: #fff;
      border: 1px solid #000;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      height: 40px;
      margin-top: 0;
      padding: 10px 17px;
      width: 287px;

      &:focus,
      &:hover {
        background-color: #fff;
        outline: none !important;
      }

      &::after {
        background: url(../images/icon-arrow-down.svg) right 17px center no-repeat #fff;
        border: none;
        display: block;
        height: 30px;
        position: absolute;
        right: 0;
        top: 7px;
        width: 30px;
      }

      &.bs-placeholder {
        .filter-option {
          color: darkgrey;
        }
      }

      .filter-option {
        color: #000;
        display: block;
        font-size: 14px;
        position: absolute;
        text-transform: initial;
        width: 253px;
      }
    }

    .dropdown-menu {
      border: 1px solid #000;
      border-top: 0 none;
      color: #000;
      font-size: 14px;
      margin-bottom: 0;
      margin-top: 0;

      li {
        padding: 5px 8px 5px 17px;

        &.selected {
          background-color: #d4d4d4;
        }
        
        &.hidden {
          display: none;
        }

        a {
          color: #000;
          font-size: 14px;
          outline: none;

          span {
            outline: none;
          }
        }
      }

      &.open {
        .actions-btn {
          -webkit-border-radius: 0.2em;
          border-radius: 0.2em;
          -webkit-transition: none;
          transition: none;
          width: 48%;

          &.bs-select-all {
            background: #33647f;
            border-color: #33647f;
            margin-right: 2%;

            &:hover {
              background: #1d3a49;
              border-color: #1d3a49;
            }
          }

          &.bs-deselect-all {
            margin-left: 2%;
          }
        }
      }
    }

    &.dropup {
      .dropdown-menu.open {
        border-bottom: 0 none;
        border-top: 1px solid #000;
      }
    }

    &.open {
      .dropdown-toggle {
        &::after {
          background: url(../images/icon-arrow-down.svg) right 17px center no-repeat #fff;
          border: none;
          margin-top: 0;
          right: 17px;
          top: 4px;
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }
}

/* Required Field Indicator */
.required {
  font-weight: bold;

  &::after {
    color: $icon-red-error;
    content: '*';
  }
}

/* Calendar Date Picker */
.datepicker {
  border: 1px solid #000;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 40px;
  min-width: 287px;
  padding: 0 17px;

  &:focus {
    outline: none;
  }
}

.daterangepicker {
  border: 1px solid #000;
  margin-left: 17px;

  &::before {
   border-bottom-color: #000;
  }

  .daterangepicker_input {
    .input-mini {
      border: 1px solid #000;
      color: #000;
      height: 30px;
      padding: 0 17px 0 27px;

      &.active {
        border-color: #000;
      }
    }

    i {
      top: 6px;
    }
  }

  select {
    &.monthselect,
    &.yearselect {
      background-color: #fff;
      border: 1px solid #000;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      height: 30px;
    }
  }

  td {
    &.in-range {
      background-color: $icon-red-alpha-light;
    }

    &.active {
      background-color: $icon-red;

      &:hover {
        background-color: $icon-red;
      }
    }
  }

  .ranges {
    .range_inputs {
      .btn-success {
        background-color: $icon-red;
        border-color: $icon-red;
        font-weight: 400;
        height: 30px;
        padding: 0 15px;

        &:hover {
          background-color: $icon-red-dark;
          border-color: $icon-red-dark;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }

      .btn-default {
        background-color: #fff;
        border-color: #000;
        color: #000;
        font-weight: 400;
        height: 30px;
        padding: 0 15px;

        &:hover {
          background-color: #000;
          -webkit-box-shadow: none;
          box-shadow: none;
          color: #fff;
        }
      }
    }
  }
}

/* Toggle Button */
/* Two States Version */
.btn {
  &.btn-toggle {
    background-color: #fff;
    border: 1px solid $icon-lightgray;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 30px;
    padding: 0;
    position: relative;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    width: 52.5px;

    &::before {
      background: #ccc;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      content: '';
      height: 26px;
      left: 1px;
      position: absolute;
      top: 1px;
      -webkit-transition: left 0.2s linear;
      transition:left 0.2s linear;
      width: 26px;
    }

    &.mobile {
      -webkit-border-radius: 20px;
      border-radius: 20px;
      height: 40px;
      width: 70px;

      &::before {
        height: 36px;
        width: 36px;
      }

      &.active {
        &::before {
          left: 31px;
        }
      }
    }

    &.active {
      background-color: $icon-red;
      border-color: $icon-red;

      &::before {
        left: 23px;
      }

      &:hover {
        box-shadow: none;
      }

      &:focus {
        outline: none;
      }
    }

    &:hover {
      box-shadow: none;
    }

    &:focus,
    &.focus {
      outline: none;
    }
  }
}

/* Three States Version */
input[type=range] {
  &.range-toggle {
    -webkit-appearance: none;
    background: transparent;
    height: 30px;
    width: 78.75px;

    &::-webkit-slider-thumb {
      animate: 0.2s;
      -webkit-appearance: none;
      background: #ccc;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      cursor: pointer;
      height: 26px;
      -webkit-transition: left 0.2s linear;
      transition:left 0.2s linear;
      width: 26px;
    }

    &::-moz-range-thumb {
      animate: 0.2s;
      -webkit-appearance: none;
      background: #fff;
      border: 1px solid #ccc;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      cursor: pointer;
      height: 26px;
      -webkit-transition: left 0.2s linear;
      transition:left 0.2s linear;
      width: 26px;
    }

    &::-ms-thumb {
      animate: 0.2s;
      -webkit-appearance: none;
      background: #fff;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      cursor: pointer;
      height: 26px;
      -webkit-transition: left 0.2s linear;
      transition:left 0.2s linear;
      width: 26px;
    }

    &::-webkit-slider-runnable-track {
      animate: 0.2s;
      background-color: #a3a7a9;
      border: 1px solid #a3a7a9;
      border-radius: 15px;
      cursor: pointer;
      height: 30px;
      padding: 1px;
      -webkit-transition: background-color 0.2s ease;
      transition: background-color 0.2s ease;
      width: 100%;
    }

    &::-moz-range-track {
      animate: 0.2s;
      background-color: #a3a7a9;
      border: 1px solid #a3a7a9;
      border-radius: 15px;
      cursor: pointer;
      height: 30px;
      padding: 1px;
      -webkit-transition: background-color 0.2s ease;
      transition: background-color 0.2s ease;
      width: 100%;
    }

    &::-ms-track {
      animate: 0.2s;
      background-color: #a3a7a9;
      border: 1px solid #a3a7a9;
      border-radius: 15px;
      cursor: pointer;
      height: 30px;
      padding: 1px;
      -webkit-transition: background-color 0.2s ease;
      transition: background-color 0.2s ease;
      width: 100%;
    }

    &::-ms-fill-lower {
      background-color: #a3a7a9;
      border: 1px solid #a3a7a9;
    }

    &::-ms-fill-upper {
      background-color: #a3a7a9;
      border: 1px solid #a3a7a9;
    }

    &,
    &:focus,
    &:active {
      border: 0;
      outline: none;
    }

    &::-moz-focus-inner,
    &:-moz-focusring,
    &::-moz-focus-outer {
      border: 0;
      outline: none;
    }

    &:focus {
      &::-webkit-slider-runnable-track {
        background-color: #a3a7a9;
        border: 1px solid #a3a7a9;
        outline: none;
      }

      &::-ms-fill-lower {
        background-color: #a3a7a9;
        border: 1px solid #a3a7a9;
        outline: none;
      }

      &::-ms-fill-upper {
        background-color: #a3a7a9;
        border: 1px solid #a3a7a9;
        outline: none;
      }
    }

    &.range-false {
      &::-webkit-slider-runnable-track {
        background-color: #fff;
        border-color: #a3a7a9;
      }

      &::-moz-range-track {
        background-color: #fff;
        border-color: #a3a7a9;
      }

      &::-ms-track {
        background-color: #fff;
        border-color: #a3a7a9;
      }
    }

    &.range-neutral {
      &::-webkit-slider-runnable-track {
        background-color: $icon-blue;
        border-color: $icon-blue;
      }

      &::-moz-range-track {
        background-color: $icon-blue;
        border-color: #a3a7a9;
      }

      &::-ms-track {
        background-color: $icon-blue;
        border-color: $icon-blue;
      }
    }

    &.range-true {
      &::-webkit-slider-runnable-track {
        background-color: $icon-red;
        border-color: $icon-red;
      }

      &::-moz-range-track {
        background-color: $icon-red;
        border-color: $icon-red;
      }

      &::-ms-track {
        background-color: $icon-red;
        border-color: $icon-red;
      }
    }

    &.mobile {
      height: 40px;
      width: 105px;

      &::-webkit-slider-thumb {
        height: 36px;
        width: 36px;
      }

      &::-moz-range-thumb {
        height: 36px;
        width: 36px;
      }

      &::-ms-thumb {
        height: 36px;
        width: 36px;
      }

      &::-webkit-slider-runnable-track {
        border-radius: 20px;
        height: 40px;
      }

      &::-moz-range-track {
        border-radius: 20px;
        height: 40px;
      }

      &::-ms-track {
        border-radius: 20px;
        height: 40px;
      }
    }
  }
}


/* File Upload Button */
.form-control-file {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;

  & + label {
    background-color: #fff;
    border: 1px solid $icon-red;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    color: $icon-red;
    cursor: pointer;
    display: table;
    font-size: 16px;
    font-weight: normal;
    padding: 10px 30px;
    text-transform: uppercase;

    & * {
      pointer-events: none;
    }

    & svg {
      fill: currentColor;
      height: 1em;
      margin-right: 0.25em;
      margin-top: -0.25em;
      vertical-align: middle;
      width: 1em;
    }

    &:hover {
      border-color: $icon-red-dark;
      color: $icon-red-dark;
    }
  }

  &:focus + .form-control-file-label {
    border-color: $icon-red-dark;
    color: $icon-red-dark;
  }
}
